import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Icon, IconNameType } from '../Icon/Icon';
import styles from './Tooltip.module.css';

type TooltipProps = {
  icon?: IconNameType;
  value?: ReactNode;
  color?: string;
  className?: string;
  text: ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
};

export const Tooltip = ({ icon, value, color, className, text, position = 'top' }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const openTooltip = () => setShowTooltip(true);
  const closeTooltip = () => setShowTooltip(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setShowTooltip((oldValue) => !oldValue);
      return;
    }

    if (event.key === 'Escape') {
      closeTooltip();
    }
  };

  const containerClass = classNames(styles.container, className);
  const tooltipClass = classNames(styles.tooltip, styles.bubble, styles[position]);

  return (
    <div
      role="tooltip"
      className={containerClass}
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
      onKeyUp={handleKeyPress}
      onBlur={closeTooltip}
    >
      {icon ? <Icon name={icon} color={color} /> : value}
      {showTooltip && <div className={classNames(tooltipClass, className)}>{text}</div>}
    </div>
  );
};
