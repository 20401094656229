import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { FilterSelect, FilterSelectProps } from './FilterSelect';

export type FormFilterSelectProps = FilterSelectProps & UseControllerProps<HTMLInputElement>;

/* A component to use with redux-hook-form */
export const FormFilterSelect = React.forwardRef(
  ({ name, control, ...props }: FormFilterSelectProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { field } = useController({ name, control });

    return <FilterSelect {...props} {...field} ref={ref} />;
  }
);
