import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './Navigation.module.css';
import { NavigationLinkContent } from './NavigationLinkContent';
import { NavItem } from './types';

type NavigationLinkProps = {
  item: NavItem & { index: number };
  active?: boolean;
  children?: React.ReactNode;
};

export function NavigationLink({ item, active, children }: NavigationLinkProps) {
  return (
    <li className={cx(styles.menuItem, { [styles.active]: active })}>
      {active ? (
        <div className={styles.navLink}>
          <NavigationLinkContent step={item.index + 1} title={item.name} />
        </div>
      ) : (
        <Link className={styles.navLink} to={`../${item.id}`}>
          <NavigationLinkContent step={item.index + 1} title={item.name} />
        </Link>
      )}
      {active && children}
    </li>
  );
}
