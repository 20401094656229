import { ReactNode } from 'react';
import { Menu } from '@fnox/eternal-smooth-ui';

type TabsProps = {
  direction?: 'horizontal' | 'vertical';
  children: ReactNode;
};

const Tabs = (props: TabsProps) => {
  const { direction = 'horizontal', children } = props;

  return <Menu direction={direction}>{children}</Menu>;
};

export default Tabs;
