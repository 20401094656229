import { NavLink } from 'react-router-dom';
import { SecondaryButton } from '@simplifiers/ui/Button';
import { Icon } from '@simplifiers/ui/Icon/Icon';
import Logo from './Fortnox-Logotype-Colour-Green.svg';
import styles from './Header.module.css';

export function Header() {
  return (
    <header className={styles.header}>
      <NavLink to="/" className={styles.homeButton}>
        <Icon name="house" size="1x" />
      </NavLink>
      <img src={Logo} alt="Fortnox" className={styles.logo} />

      <SecondaryButton className={styles.helpButton} iconName="question-circle" iconPosition="left" onClick={() => {}}>
        Hjälp
      </SecondaryButton>
    </header>
  );
}
