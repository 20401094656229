import { filterRowsWithValues } from '../../utils/filters';
import { columnLabelFormatter } from '../../utils/formatters';
import { BalanceSheetTable, TableColumn, TableData, TableRow } from '../../utils/types';
import { getAnnualReportDocument } from './api';
import documentationExample from './documentationExample.json';
import {
  AnnualReportColumn,
  AnnualReportRow,
  AnnualReportTableData,
  BalanceSheetTableData,
  DocumentationReference,
} from './types';

export const annualReportLoader = async (section: 'balance-sheet' | 'income-statement') => {
  // TODO: here maybe we can have react query fetch it from the local cache if it's already there
  const annualReport = await getAnnualReportDocument();

  // TODO: Add the mock data for testing purposes but it should return null otherwise
  const documentation =
    annualReport && 'documentation' in annualReport
      ? (annualReport.documentation as DocumentationReference)
      : documentationExample;

  switch (section) {
    case 'balance-sheet':
      return getBalanceSheet(annualReport?.balanceSheet.section, documentation as DocumentationReference);
    case 'income-statement':
      return getIncomeStatement(annualReport?.incomeStatement.section.table, documentation as DocumentationReference);
    default:
      return annualReport?.incomeStatement;
  }
};

export const getBalanceSheet = (
  balanceSheet: BalanceSheetTable | null,
  documentation?: DocumentationReference
): BalanceSheetTableData & { documentation?: DocumentationReference } => {
  if (balanceSheet == null) {
    return {
      assets: { columns: [], rows: [] },
      equityAndLiabilities: { columns: [], rows: [] },
    };
  }

  const assetsColumns = extractColumns(columnLabelFormatter(balanceSheet.assets.columns, 'Tillgångar'));
  const assetsRows = extractRows(
    filterRowsWithValues(balanceSheet.assets.rows ?? []),
    1,
    assetsColumns,
    'balanceSheet.section.assets',
    documentation
  );

  const equityColumns = extractColumns(columnLabelFormatter(balanceSheet.assets.columns, 'Eget kapital och skulder'));
  const equityAndLiabilitiesRows = extractRows(
    filterRowsWithValues(balanceSheet.equityAndLiabilities.rows ?? []),
    1,
    equityColumns,
    'balanceSheet.section.equityAndLiabilities',
    documentation
  );

  return {
    documentation,
    assets: { columns: assetsColumns, rows: assetsRows },
    equityAndLiabilities: { columns: equityColumns, rows: equityAndLiabilitiesRows },
  };
};

export const getIncomeStatement = (
  incomeStatement: TableData | null,
  documentation?: DocumentationReference
): AnnualReportTableData & { documentation?: DocumentationReference } => {
  if (incomeStatement == null) {
    return {
      columns: [],
      rows: [],
    };
  }

  const columns = extractColumns(columnLabelFormatter(incomeStatement.columns, 'Resultaträkning'));
  const rows = extractRows(
    filterRowsWithValues(incomeStatement.rows ?? []),
    1,
    columns,
    'incomeStatement.section.table',
    documentation
  );

  return {
    columns,
    rows,
  };
};

const extractColumns = (columns: TableColumn[]): AnnualReportColumn[] => {
  const getDataType = (col: TableColumn): AnnualReportColumn['dataType'] => {
    if (col.dataType === 'numeric') {
      return 'number';
    }

    return 'string';
  };

  return columns.map((col) => {
    return {
      id: col.id,
      dataType: getDataType(col),
      value: col.label ?? '',
    };
  });
};

const extractRows = (
  rows: TableRow[],
  level: number,
  simplifiedColumns: AnnualReportColumn[],
  basePath: string,
  documentation?: DocumentationReference
): AnnualReportRow[] => {
  let allRows: AnnualReportRow[] = [];

  const getType = (level: number, row: TableRow): AnnualReportRow['type'] => {
    if (level === 1 && !['alwaysDisplaySum', 'sum'].includes(row.type || '')) {
      return 'section';
    }

    if (row.type === 'alwaysDisplaySum') {
      return 'sum';
    }

    return row.type as AnnualReportRow['type'];
  };

  rows.forEach((row: TableRow) => {
    if (!row.cells) {
      return;
    }

    const idFullPath = `${basePath}.${row.id}`;

    const tooltip = documentation?.[idFullPath] || null;

    allRows.push({
      id: row.id,
      type: getType(level, row),
      level,
      cells: extractCells(row, simplifiedColumns),
      idFullPath,
      tooltip,
    });

    if (row.rows && row.rows.length > 0) {
      allRows = allRows.concat(extractRows(row.rows, level + 1, simplifiedColumns, idFullPath, documentation));
    }
  });

  return allRows;
};

const extractCells = (row: TableRow, simplifiedColumns: AnnualReportColumn[]): AnnualReportColumn[] => {
  const columnsKeys = simplifiedColumns.map((col) => col.id);

  const cells = row.cells ?? {};

  const getDataType = (id: string): AnnualReportColumn['dataType'] => {
    const typeMap = {
      label: 'string',
      notes: 'number',
      year: 'number',
      previousYear: 'number',
    };

    return typeMap[id];
  };

  if (row.cells == null) {
    return [
      {
        id: 'label',
        dataType: 'string',
        value: '',
      },
    ];
  }

  return columnsKeys.map((key) => {
    const value =
      row.type === 'account' && key === 'label' ? `${row.id} ${cells['label']?.value ?? ''}` : cells[key]?.value ?? '';

    return {
      id: key,
      dataType: getDataType(key),
      value,
    };
  });
};
