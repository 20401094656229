import { Text as FText } from '@fnox/eternal-smooth-ui';

export type TextColors = typeof FText extends (props: { color: infer T }) => any ? T : never;

type TextProps = {
  className?: string;
  color?: TextColors;
  children: React.ReactNode;
};

export const Text = (props: TextProps) => {
  const { className, color, children } = props;
  return (
    <FText className={className} color={color}>
      {children}
    </FText>
  );
};
