import { Navigate, RouteObject } from 'react-router-dom';
import { AnnualReport } from './AnnualReport';
import { documentRoutes } from './pages/Document/route';
import { paymentAndVouchersRoutes } from './pages/PaymentAndVouchers/route';

export const annualReportRoutes: RouteObject = {
  path: 'annual-report',
  element: <AnnualReport />,
  children: [
    { index: true, element: <Navigate to="document" replace /> },
    { path: '*', element: <Navigate to="document" replace /> },
    documentRoutes,
    paymentAndVouchersRoutes,
  ],
};
