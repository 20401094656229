import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@simplifiers/ui/Table';
import { Title } from '@simplifiers/ui/Typography';
import { PersonalNumber } from '@simplifiers/ui/Typography/PersonalNumber/PersonalNumber';
import { REPRESENTATIVE_OPTIONS, Representative } from '../../../../types';
import { getRepresentativeFullName } from '../../../../utils/formatters';
import styles from './BoardTable.module.css';

interface BoardTableProps {
  title: string;
  data: Representative[];
}

export const BoardTable = ({ title, data }: BoardTableProps) => {
  return (
    <div className={styles.table}>
      <Title className={styles.title}>{title}</Title>
      <Table>
        <TableHead>
          <TableHeader width="25%">Roll</TableHeader>
          <TableHeader width="20%">Namn</TableHeader>
          <TableHeader>Personnummer</TableHeader>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={`${index}-${item.personalNumber}`}>
              <TableCell>{REPRESENTATIVE_OPTIONS[item.roles[0].code]}</TableCell>
              <TableCell>{getRepresentativeFullName(item)}</TableCell>
              <TableCell>
                <PersonalNumber value={item.personalNumber} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
