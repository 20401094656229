import questions from 'api/questions.json';
import { getAnswersFromLocalStorage, saveAnswerToLocalStorage } from 'localStorage/localStorage';
import {
  Answer,
  Category,
  OrderedQuestion,
  Question,
  RootCategory,
  Structure,
} from './types';

export const getQuestionsStructure = (): Structure => {
  return questions as Structure;
};

export const getAllQuestions = (): OrderedQuestion[] => {
  const struct = getQuestionsStructure();
  return extractOrderedQuestions(struct);
};

export const getQuestionByNumber = (questionNumber: number): OrderedQuestion | undefined => {
  const questions = getAllQuestions();

  return questions.find((q) => q.questionNumber === questionNumber);
};

export const getQuestionById = (questionId: string): OrderedQuestion => {
  const allQuestions = getAllQuestions();
  const question = allQuestions.find((q) => q.question.id === questionId);

  if (!question) {
    throw new Error(`Question with id ${questionId} not found`);
  }

  return question;
};

export const areQuestionsOfCategoryAnswered = (categoryId: string) => {
  if (!categoryId) {
    return false;
  }

  const allQuestions = getAllQuestions();
  const questionsOfCategory = allQuestions.filter((q) => q.categoryId === categoryId);

  return questionsOfCategory.every((q) => q.answer !== '');
};

export const answerQuestion = async ({ questionId, answer }: { questionId: string; answer: string }) => {
  const question = getQuestionById(questionId);
  if (question == null) {
    throw new Error(`Question with id ${questionId} not found`);
  }

  saveAnswerToLocalStorage({ questionId, answer });
};

export const getAllAnswers = () => {
  return getAnswersFromLocalStorage();
};

// TODO: add test cases for this function
const extractOrderedQuestions = (structure: Structure): OrderedQuestion[] => {
  const orderedQuestions: OrderedQuestion[] = [];
  let questionCounter = 1;

  const allAnswers = getAllAnswers();

  function traverseCategories(categories: Category[]) {
    categories.sort((a, b) => a.orderNumber - b.orderNumber);

    for (const category of categories) {
      category.questions.sort((a, b) => a.orderNumber - b.orderNumber);
      for (const question of category.questions) {
        const answer = allAnswers.find((a) => a.questionId === question.id)?.answer;
        orderedQuestions.push({
          question,
          categoryId: category.id,
          categoryName: category.name,
          questionNumber: questionCounter++,
          answer: answer || '',
        });
      }

      if (category.subCategories) {
        traverseCategories(category.subCategories);
      }
    }
  }

  traverseCategories(structure.categories);

  return orderedQuestions;
};

// Get own and all nested categories questions
const getCategoriesQuestions = (categories?: Category[]): Array<Question> => {
  if (!categories) {
    return [];
  }

  return categories.reduce((acc, c) => {
    return [...acc, ...c.questions, ...getCategoriesQuestions(c.subCategories)]
  }, [] as Array<Question>);
}

/** Get top level categories with all nested questions
 * @param structure - questions structure.
 * @returns Array of top level categories with all child questions with available answers.
 *
 * @example
 * {
 *  lastUpdated: '',
 *  categories: [
 *    id: 'c1',
 *    name: 'category 1',
 *    questions: [
 *      { id: 'q1', title: 'question 1' }
 *      ...
 *    ],
 *    subCategories: [
 *      {
 *        id: 'c11',
 *        name: 'category 1.1',
 *        questions: [ { id: 'q5', title: 'question 5' } ... ]
*       }
 *    ]
*    ]
 * }
 *
 * will be transformed to:
 * [
 *   {
 *     id: 'c1',
 *     name: 'category 1',
 *     questions: {
 *       q1: { id: 'q1', title: 'question 1', answer: '' },
 *       ...
 *       q5: { id: 'q5', title: 'question 5', answer: '' }
 *     }
 *   }
 * ]
 *  */
export const getRootCategoriesWithQuestions = (structure: Structure): Array<RootCategory> => {
  // Answers map where key = questionId
  const allAnswers: Record<string, Answer> = getAllAnswers().reduce((acc, item) => ({...acc, [item.questionId]: item.answer}), {});

  return structure.categories.map(c => {
    const categoryQuestions = [...c.questions, ...getCategoriesQuestions(c.subCategories)];

    return {
      id: c.id,
      name: c.name,
      questions: categoryQuestions.reduce((acc, q) => {
        return { ...acc,
          [q.id]: {...q, answer: allAnswers[q.id] || '' }
        }
      }, {})
    }
  })
};
