import { RouteObject } from 'react-router-dom';
import { Root } from './Root';
import { ErrorPage } from './pages/Error';
import { reportRoutes } from './pages/Report/route';
import { startRoutes } from './pages/Start/route';

export const rootRoutes: RouteObject = {
  path: '/',
  element: <Root />, //header
  errorElement: <ErrorPage />,
  children: [startRoutes, reportRoutes],
};
