import { Navigate, RouteObject } from 'react-router-dom';
import { Submission } from './Submission';
import { archiveRoutes } from './pages/Archive/route';
import { digitalSubmissionRoutes } from './pages/DigitalSubmission/route';

export const submissionRoutes: RouteObject = {
  path: 'submission',
  element: <Submission />,
  children: [
    { index: true, element: <Navigate to="digital-submission" replace /> },
    { path: '*', element: <Navigate to="digital-submission" replace /> },
    digitalSubmissionRoutes,
    archiveRoutes,
  ],
};
