import React from 'react';
import { Alert as FAlert } from '@fnox/eternal-smooth-ui';

type AlertProps = {
  children: React.ReactNode;
  description?: string;
  type?: 'info' | 'success' | 'error' | 'warning';
  maxWidth?: number;
  onClose?: () => void;
};

export const Alert = (props: AlertProps) => {
  const { children, description, type = 'info', maxWidth, onClose } = props;

  return (
    <FAlert description={description} type={type} maxWidth={maxWidth} onClose={onClose}>
      {children}
    </FAlert>
  );
};
