import { Heading } from '@fnox/eternal-smooth-ui';

type TitleProps = {
  children: React.ReactElement | string;
  className?: string;
  large?: boolean;
};

export const Title = (props: TitleProps) => {
  const { children, className, large } = props;

  return (
    <Heading as={large ? 'h1' : 'h2'} className={className}>
      {children}
    </Heading>
  );
};
