import { Heading } from '@fnox/eternal-smooth-ui';

type CaptionProps = {
  children: React.ReactElement | string;
  className?: string;
};

export const Caption = ({ children, className }: CaptionProps) => {
  return (
    <Heading as={'h3'} className={className}>
      {children}
    </Heading>
  );
};
