import { Transaction, VoucherFormData } from './types';

export const voucherSeriesOptions = [
  { label: 'A (Redovisning)', value: 'A' },
  { label: 'I (Bokslut)', value: 'I' },
];

export const emptyTransaction: Transaction = {
  debitAccount: '',
  creditAccount: '',
  debit: '',
  credit: '',
};

export const initialFormValues: VoucherFormData = {
  description: '',
  series: '',
  date: '',
  transactions: [emptyTransaction],
};
