import styles from './Navigation.module.css';

type NavigationLinkContentProps = {
  step: number;
  title: string;
};

export function NavigationLinkContent({ step, title }: NavigationLinkContentProps) {
  return (
    <>
      <mark className={styles.step}>{step}</mark>
      <span className={styles.title}>{title}</span>
    </>
  );
}
