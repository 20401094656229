import { AccountsRange } from '../../../types';

export const getAccountsRange = (from: number, to: number): Array<number> => {
  const length = to - from;
  if (length == null || Number.isNaN(length)) {
    return [];
  }

  const result = [from];
  for (let i = 1; i <= length; i++) {
    result.push(from + i);
  }
  return result;
};

export const getAllAccounts = (ranges: Array<AccountsRange>): Array<number> => {
  if (!ranges) {
    return [];
  }

  return ranges.reduce((acc, range) => {
    return [...acc, ...getAccountsRange(range[0], range[1])];
  }, [] as Array<number>);
};
