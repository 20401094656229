import { PrimaryButton } from '@simplifiers/ui/Button';
import { Icon } from '@simplifiers/ui/Icon/Icon';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Caption, Text } from '@simplifiers/ui/Typography';
import styles from './SieUploader.module.css';

type Props = {
  onUpload: () => void;
};

export function SieUploader(props: Props) {
  const { onUpload } = props;

  return (
    <Section justifyItems="center" alignContent="center" className={styles.sieUploaderContainer}>
      <Icon name="cloud-upload" size="2x" />
      <Caption>Släpp din bokförning här eller ladda upp fil</Caption>
      <Text>(.sie, .se, se4, .txt)</Text>
      <PrimaryButton onClick={onUpload}>Välj fil</PrimaryButton>
    </Section>
  );
}
