import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Tab, Tabs } from '@simplifiers/ui/Tabs';
import { Text, Title } from '@simplifiers/ui/Typography';
import styles from './Overview.module.css';
import { ReportStepStatus } from './types';

const initialSteps = [
  { step: '1', title: 'Bokslutsfrågor', status: ReportStepStatus.IN_PROGRESS },
  { step: '2', title: 'Årsredovisning', status: ReportStepStatus.NOT_STARTED },
  { step: '3', title: 'Signering', status: ReportStepStatus.DONE },
  { step: '4', title: 'Inlämning', status: ReportStepStatus.NOT_STARTED },
  { step: '5', title: 'Skatt', status: ReportStepStatus.IN_PROGRESS },
];

const overviewTabs = [
  { title: 'Pågående', path: 'current-year' },
  { title: 'Föregående', path: 'previous-year' },
];

export function Overview() {
  const navigate = useNavigate();
  const location = useLocation();
  const [steps, setSteps] = useState(initialSteps);

  const isReportDone = steps.every((step) => step.status === ReportStepStatus.DONE);

  const title = isReportDone ? 'Årsavslut' : 'Årsavslutet är påbörjat';
  const subtitle = isReportDone ? 'Bra jobbat, nu är du klar!' : 'Du har kommit en bit på vägen, snart är du klar!';

  return (
    <Section className={styles.report}>
      <Title large className={styles.title}>
        {title}
      </Title>
      <Text className={styles.subtitle}>{subtitle}</Text>
      <Tabs>
        {overviewTabs.map((tab) => (
          <Tab
            key={tab.path}
            active={location.pathname.endsWith(tab.path)}
            title={tab.title}
            onClick={() => navigate(tab.path)}
          />
        ))}
      </Tabs>
      <Outlet context={{ steps, setSteps, isReportDone, initialSteps }} />
    </Section>
  );
}
