import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Input, InputProps } from './Input';

export type FormInputProps = InputProps & UseControllerProps<HTMLInputElement | HTMLTextAreaElement>;

/* A component to use with redux-hook-form */
export const FormInput = React.forwardRef(
  ({ name, control, ...props }: FormInputProps, ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
    const { field } = useController({ name, control });

    return <Input {...props} {...field} ref={ref} />;
  }
);
