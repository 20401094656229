import { Navigate, RouteObject } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { BasicInformation } from './BasicInformation';
import { basicInformationLoader } from './loader';
import { boardRolesRoutes } from './pages/BoardRoles/route';
import { companyInformationRoutes } from './pages/CompanyInformation/route';
import { ownerRoutes } from './pages/Owner/route';

const queryClient = new QueryClient();

export const basicInformationRoutes: RouteObject = {
  path: 'information',
  element: <BasicInformation />,
  loader: basicInformationLoader(queryClient),
  errorElement: <div>Something went wrong loading your users' data</div>,
  children: [
    { index: true, element: <Navigate to="company" replace /> },
    { path: '*', element: <Navigate to="company" replace /> },
    companyInformationRoutes,
    boardRolesRoutes,
    ownerRoutes,
  ],
};
