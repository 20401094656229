import React from 'react';
import { NavLink as RNavLink, NavLinkProps as RNavLinkProps } from 'react-router-dom';
import { Icon, IconNameType, IconSize } from '@simplifiers/ui/Icon/Icon';
import cx from 'classnames';
import styles from './NavLink.module.css';

interface LinkProps extends RNavLinkProps {
  to: string;
  children: React.ReactElement | string[] | string;
  iconName?: IconNameType;
  iconPosition?: 'start' | 'end'; // "start" by default
  iconSize?: IconSize;
  className?: string;
  onClick?: () => void;
}

export const NavLink = (props: LinkProps) => {
  const { children, iconName, iconPosition = 'start', iconSize = 'sm', className, ...rest } = props;

  return (
    <RNavLink
      {...rest}
      className={cx(
        styles.link,
        { [styles.iconStart]: iconPosition === 'start', [styles.iconEnd]: iconPosition === 'end' },
        className
      )}
    >
      {iconName ? <Icon name={iconName} size={iconSize} className={styles.icon} /> : null}
      {children}
    </RNavLink>
  );
};
