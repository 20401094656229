import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Datepicker, DatepickerProps } from './Datepicker';

export type FormDatepickerProps = DatepickerProps & UseControllerProps<HTMLInputElement>;

/* A component to use with redux-hook-form */
export const FormDatepicker = React.forwardRef(
  ({ name, control, ...props }: FormDatepickerProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { field } = useController({ name, control });

    return <Datepicker {...props} {...field} ref={ref} />;
  }
);
