import { DocumentationObject } from 'routes/pages/Report/pages/AnnualReport/pages/Document/types';
import { When } from '@simplifiers/ui/When';

type TooltipTextProps = {
  accountRange: DocumentationObject['accounts'];
  accountDescription: DocumentationObject['tooltip'];
};

export const TooltipText = ({ accountRange, accountDescription }: TooltipTextProps) => {
  return (
    <>
      <When isTrue={!!accountRange}>
        <p>
          <strong>Kontointervall: </strong>
          {accountRange}
        </p>
      </When>
      <p>
        <strong>Beskrivning: </strong>
        {accountDescription}
      </p>
    </>
  );
};
