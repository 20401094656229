import { Navigate, RouteObject } from 'react-router-dom';
import { Tax } from './Tax';
import { digitalSubmissionRoutes } from './pages/DigitalSubmission/route';
import { ink2Routes } from './pages/Ink2/route';
import { k10Routes } from './pages/K10/route';

export const taxRoutes: RouteObject = {
  path: 'tax',
  element: <Tax />,
  children: [
    { index: true, element: <Navigate to="ink2" replace /> },
    { path: '*', element: <Navigate to="ink2" replace /> },
    ink2Routes,
    k10Routes,
    digitalSubmissionRoutes,
  ],
};
