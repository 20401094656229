import classNames from 'classnames/bind';
import styles from './InlineContent.module.css';

const cx = classNames.bind(styles);

type InlineContentProps = {
  children: React.ReactNode;
  justifyContent?: 'space-between' | 'space-around' | 'space-evenly' | 'flex-start' | 'flex-end' | 'center';
  alignItems?: 'start' | 'center' | 'end' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
};
export function InlineContent(props: InlineContentProps) {
  const { children, justifyContent, alignItems } = props;

  const containerClassName = cx('inlineContent', {
    [`justify-content-${justifyContent}`]: !!justifyContent,
    [`align-items-${alignItems}`]: !!alignItems,
  });

  return <div className={containerClassName}>{children}</div>;
}
