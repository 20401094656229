import { ErrorOption } from 'react-hook-form';
import { emptyTransaction } from './constants';
import { VoucherFormData } from './types';

export const validate = (data: VoucherFormData, difference: number): Record<string, ErrorOption> => {
  const errors = {};
  if (difference !== 0) {
    errors['root.difference'] = {
      message:
        'För att kunna bokföra preliminärt behöver debet och kredit balansera. Justera beloppen så differensen blir 0.',
    };
  }
  const transactionsValid = data.transactions.every((t) =>
    Object.keys(t).every((fieldName) => t[fieldName] !== emptyTransaction[fieldName])
  );
  if (!transactionsValid) {
    errors['root.transactions'] = {
      message: 'För att kunna bokföra preliminärt behöver du ha minst ett konto inlagt för debet och ett för kredit.',
    };
  }

  return errors;
};
