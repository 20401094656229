import { Grid } from '../Grid/Grid';
import styles from './Section.module.css';

type SectionProps = {
  children: React.ReactNode;
  alignContent?: 'start' | 'center' | 'end' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  className?: string;
};

export const Section = (props: SectionProps) => {
  const { children, alignContent, justifyItems, className } = props;

  return (
    <Grid
      rowGap="md"
      alignContent={alignContent}
      justifyItems={justifyItems}
      className={`${styles.container} ${className}`}
    >
      {children}
    </Grid>
  );
};
