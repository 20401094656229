import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Icon, IconNameType } from '../Icon/Icon';
import styles from './IconTooltip.module.css';

type IconTooltipProps = {
  icon: IconNameType;
  color?: string;
  className?: string;
  text: ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
};

export const IconTooltip = ({ icon, color, className, text, position = 'top' }: IconTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const containerClass = classNames(styles.container, className);
  const tooltipClass = classNames(styles.tooltip, styles.bubble, styles[position]);

  return (
    <div
      role="tooltip"
      className={containerClass}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Icon name={icon} color={color} />
      {showTooltip && <div className={tooltipClass}>{text}</div>}
    </div>
  );
};
