import { IconTooltip } from '@simplifiers/ui/IconTooltip/IconTooltip';
import { AnnualReportRowWithTooltip } from '../../../pages/Document/types';
import { TooltipText } from '../TooltipText';

const accountRange = (accounts: string) => accounts.replaceAll(':', '-').replaceAll('+', ', ');

interface TooltipCellContentProps {
  row: AnnualReportRowWithTooltip;
}

export const TooltipCellContent = ({ row }: TooltipCellContentProps) => {
  return (
    <IconTooltip
      icon="circle-info"
      text={
        <TooltipText
          accountRange={accountRange(String(row.tooltip?.accounts))}
          accountDescription={String(row.tooltip?.tooltip)}
        />
      }
      position="left"
    />
  );
};
