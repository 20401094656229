import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Section } from '@simplifiers/ui/Layout/Section';
import { Tab, Tabs } from '@simplifiers/ui/Tabs';

const basicInformationTabs = [
  { title: 'Företagsinformation', path: 'company' },
  { title: 'Bolag- och styrelseroller', path: 'board-roles' },
  { title: 'Ägare', path: 'owner' },
];

export const BasicInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Section>
      <Tabs>
        {basicInformationTabs.map((tab) => (
          <Tab
            key={tab.path}
            active={location.pathname.endsWith(tab.path)}
            title={tab.title}
            onClick={() => navigate(tab.path)}
          />
        ))}
      </Tabs>
      <Outlet />
    </Section>
  );
};
