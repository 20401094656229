type WhenProps = {
  isTrue: boolean;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
};

export function When(props: WhenProps) {
  const { isTrue, fallback, children } = props;

  if (isTrue) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
}
