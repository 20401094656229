import { useParams } from 'react-router-dom';
import { Grid } from '@simplifiers/ui/Layout/Grid';
import { ProgressItem } from './ProgressItem';
import { Question, RootCategory } from '../../types';

type ProgressProps = {
  categories: Array<RootCategory>;
}

const getAnswersAmount = (questions: Record<string, Question & { answer: string }>): number => {
  return Object.values(questions).filter(q => q.answer !== "").length
}

export const Progress = ({ categories = [] }: ProgressProps) => {
  const { questionId } = useParams();

  if (!categories.length) {
    return null;
  }

  return (
    <Grid columns={{ base: categories.length }} colGap="md">
      {categories.map(c => (
        <ProgressItem
          isActive={questionId ? Object.keys(c.questions).includes(questionId) : false}
          key={c.id}
          title={c.name}
          answers={getAnswersAmount(c.questions)}
          total={Object.keys(c.questions).length}
        />
      ))}
    </Grid>
  );
};
