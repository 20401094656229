import { Outlet } from 'react-router-dom';
import styles from './Root.module.css';
import { Header } from './components/Header';

export function Root() {
  return (
    <div className={styles.screen}>
      <Header />
      <Outlet />
    </div>
  );
}
