import { ComponentProps, FunctionComponentElement } from 'react';
import { TableRow as FTableRow } from '@fnox/eternal-smooth-ui';
import { TableCell } from './TableCell';

type TableRowProps = {
  children:
    | FunctionComponentElement<ComponentProps<typeof TableCell>>
    | FunctionComponentElement<ComponentProps<typeof TableCell>>[];
  onClick?: (() => void) | undefined;
  className?: string | undefined;
  rowId?: string | undefined;
  rowHeight?: '48' | '56' | '80' | undefined;
};

export function TableRow(props: TableRowProps) {
  const { children, onClick, className, rowId, rowHeight } = props;
  return (
    <FTableRow rowId={rowId} onClick={onClick} className={className} rowHeight={rowHeight}>
      {children}
    </FTableRow>
  );
}
