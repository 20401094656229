import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { Content } from '../../components/Content';
import { Navigation } from '../../components/Navigation';
import { NavigationContext } from '../../types';
import { Progress } from './components/Progress';
import { SubMenu } from './components/SubMenu';
import { getQuestionByNumber, getQuestionsStructure, getRootCategoriesWithQuestions } from './data';
import { Structure } from './types';

const accountsQuery = () => ({
  queryKey: ['accounts'],
  queryFn: async () => getQuestionsStructure() as Structure,
});

export const loader = (queryClient: QueryClient) => async () => {
  const query = accountsQuery();
  const result = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
  return result;
};

export default function Accounts() {
  const navigate = useNavigate();
  const location = useLocation();
  const { collapsed, toggleNavigation } = useOutletContext<NavigationContext>();
  const { data, isLoading } = useQuery(accountsQuery());

  useEffect(() => {
    const pathSections = location.pathname.split('/');
    if (pathSections.length === 4) {
      const firstQuestion = getQuestionByNumber(1);
      navigate(`${location.pathname}/${firstQuestion?.categoryId}/questions/${firstQuestion?.question.id}`);
    }
  }, [location.pathname, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data?.categories == null) {
    return <div>No categories found</div>;
  }

  return (
    <>
      <Navigation activeId="accounts" collapsed={collapsed} onToggle={toggleNavigation}>
        {!collapsed && <SubMenu categories={data.categories} />}
      </Navigation>

      <Content>
        <Progress categories={getRootCategoriesWithQuestions(data)} />
        <Outlet />
      </Content>
    </>
  );
}
