import { useRouteError } from 'react-router-dom';

type RouteError = {
  statusText?: string;
  message?: string;
};

// This bit is much better done with https://github.com/colinhacks/zod or similar schema checkers
function isRouteError(error: unknown): error is RouteError {
  if (typeof error !== 'object' || error === null) return false;

  if ('statusText' in error && typeof error.statusText === 'string') return true;
  if ('message' in error && typeof error.message === 'string') return true;

  return false;
}

export function ErrorPage() {
  const error = useRouteError();

  if (!isRouteError(error)) {
    console.error(`Error format is wrong!  ${error}`);
    return null;
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
