import { Navigate, RouteObject } from 'react-router-dom';
import { Overview } from './Overview';
import { currentYearRoutes } from './pages/CurrentYear/route';
import { previousYearRoutes } from './pages/PreviousYear/route';

export const overviewRoutes: RouteObject = {
  path: 'overview',
  element: <Overview />, // years tabs
  children: [
    { index: true, element: <Navigate to="current-year" replace /> },
    { path: '*', element: <Navigate to="current-year" replace /> },
    currentYearRoutes,
    previousYearRoutes,
  ],
};
