import { MenuItem } from '@fnox/eternal-smooth-ui';

type TabProps = {
  title: string;
  onClick: () => void;
  active?: boolean;
  direction?: 'horizontal' | 'vertical';
};

const Tab = (props: TabProps) => {
  const { onClick, active, title, direction = 'horizontal' } = props;

  return (
    <MenuItem direction={direction} onClick={onClick} active={active}>
      {title}
    </MenuItem>
  );
};

export default Tab;
