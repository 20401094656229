import { client } from 'api/config';
import { useQuery } from '@tanstack/react-query';
import { basicCompanyInformationQuery } from '../../loader';
import { getExecutives } from '../../utils/filters';
import { BoardTable } from './components/BoardTable';

export const BoardRoles = () => {
  const { data: info, isLoading } = useQuery(basicCompanyInformationQuery(client.id));
  const representatives = info?.representatives;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (representatives == null) {
    return null;
  }

  return (
    <>
      <BoardTable title="Bolagsroller" data={getExecutives(representatives)} />
      <BoardTable title="Styrelseroller" data={representatives} />
    </>
  );
};
