import { AwesomeIcon } from '@fnox/eternal-smooth-ui';

export type IconNameType = typeof AwesomeIcon extends (props: { name: infer T }) => any ? T : never;
export type IconSize = typeof AwesomeIcon extends (props: { size: infer T }) => any ? T : never;

type IconProps = {
  name: IconNameType;
  color?: string;
  size?: IconSize;
  className?: string;
};

export const Icon = (props: IconProps) => {
  const { name, color, size, className } = props;

  return <AwesomeIcon name={name} color={color} size={size} className={className} />;
};
