import React from 'react';
import { FilterCalendar } from '@fnox/eternal-smooth-ui';

export type DatepickerProps = {
  label: string;
  disabled?: boolean;
  value: string;
  onChange: (v: string) => void;
};

export const Datepicker = React.forwardRef(
  ({ label, onChange, ...props }: DatepickerProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <FilterCalendar
        title={label}
        onSelect={onChange}
        onClearSelection={() => onChange('')}
        {...props}
        inputRef={ref}
      />
    );
  }
);
