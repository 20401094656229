export enum ReportStepStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export type FinancialYearReportContext = {
  steps: { step: string; title: string; status: ReportStepStatus }[];
  setSteps: React.Dispatch<React.SetStateAction<FinancialYearReportContext['steps']>>;
  isReportDone: boolean;
  initialSteps: FinancialYearReportContext['steps'];
};
