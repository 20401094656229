export const client = {
  id: '82aac0d6-4152-436c-b174-570a8cb15f13',
  financialYears: [
    {
      id: 3699,
      start: '2021-01-01',
      end: '2021-12-31',
    },
    {
      id: 70954,
      start: '2019-11-18',
      end: '2020-12-31',
    },
    {
      id: 70955,
      start: '2021-01-01',
      end: '2021-12-31',
    },
    {
      id: 70952,
      start: '2022-01-01',
      end: '2022-10-31',
    },
    {
      id: 70951,
      start: '2022-11-01',
      end: '2023-12-31',
    },
    {
      id: 70956,
      start: '2024-01-01',
      end: '2024-12-31',
    },
  ],
};
