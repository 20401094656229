import { Navigate, RouteObject } from 'react-router-dom';
import { Document } from './Document';
import { annualGeneralMeetingRoutes } from './pages/AnnualGeneralMeeting/route';
import { balanceSheetRoutes } from './pages/BalanceSheet/route';
import { incomeStatementRoutes } from './pages/IncomeStatement/route';
import { managementReportRoutes } from './pages/ManagementReport/route';
import { notesRoutes } from './pages/Notes/route';

export const documentRoutes: RouteObject = {
  path: 'document',
  element: <Document />,
  errorElement: <span>Something went wrong when loading the annual report</span>,
  children: [
    { index: true, element: <Navigate to="income-statement" replace /> },
    { path: '*', element: <Navigate to="income-statement" replace /> },
    incomeStatementRoutes,
    balanceSheetRoutes,
    notesRoutes,
    managementReportRoutes,
    annualGeneralMeetingRoutes,
  ],
};
