import { RouteObject } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import Accounts, { loader as accountsLoader } from './Accounts';
import Question from './Question/Question';

// TODO: maybe we should declare this in the root project and pass it down to accountsRoute
const queryClient = new QueryClient();

export const accountsRoute: RouteObject = {
  path: 'accounts/',
  element: <Accounts />,
  loader: accountsLoader(queryClient),
  children: [
    {
      path: ':categoryId/questions/:questionId',
      element: <Question />,
    },
  ],
};
