import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@simplifiers/ui/Button';
import { Icon } from '@simplifiers/ui/Icon/Icon';
import { GridItem } from '@simplifiers/ui/Layout/Grid';
import { InlineContent } from '@simplifiers/ui/Layout/InlineContent';
import { Paper, PaperProps } from '@simplifiers/ui/Paper';
import { SubTitle, Text } from '@simplifiers/ui/Typography';
import { REPORT_STEP_STATUS_TEXT } from '../../constants';
import { ReportStepStatus } from '../../types';
import { ProgressBar } from '../ProgressBar';
import styles from './ReportStep.module.css';

interface GridStepProps {
  step: string;
  title: string;
  status: ReportStepStatus;
}

const getPaperType = (status: ReportStepStatus): PaperProps['type'] => {
  switch (status) {
    case ReportStepStatus.IN_PROGRESS:
      return 'warning';
    case ReportStepStatus.DONE:
      return 'success';
    case ReportStepStatus.NOT_STARTED:
      return 'info';
    default:
      return 'default';
  }
};

const calculateProgress = (status: string) => {
  if (status === ReportStepStatus.IN_PROGRESS) {
    return 50;
  } else if (status === ReportStepStatus.DONE) {
    return 100;
  } else {
    return 0;
  }
};

export const ReportStep = ({ step, title, status }: GridStepProps) => {
  const navigate = useNavigate();

  // For visualization purpose only, logic will be adjusted later
  const progress = calculateProgress(status);

  const handleOnClick = () => {
    navigate(`/report/1/accounts`);
  };

  const showButton = status === ReportStepStatus.IN_PROGRESS;

  return (
    <GridItem>
      <Paper className={styles.stepContainer} type={getPaperType(status)}>
        <InlineContent justifyContent="flex-start" alignItems="center">
          <div className={styles.gridStep}>{status === ReportStepStatus.DONE ? <Icon name="check" /> : step}</div>
          <SubTitle className={styles.title}>{title}</SubTitle>
        </InlineContent>

        <div className={styles.statusContainer}>
          <Text className={styles.statusText}>{REPORT_STEP_STATUS_TEXT[status]}</Text>
          <ProgressBar className={styles.progressBar} fill={progress} />
          {showButton && (
            <PrimaryButton className={styles.continueButton} onClick={handleOnClick}>
              Fortsätt
            </PrimaryButton>
          )}
        </div>
      </Paper>
    </GridItem>
  );
};
