import React from 'react';
import { Icon } from '@simplifiers/ui/Icon';
import cx from 'classnames';
import { reportNavigation } from '../../constants';
import styles from './Navigation.module.css';
import { NavigationLink } from './NavigationLink';

type NavigationProps = {
  activeId?: string;
  collapsed?: boolean;
  onToggle?: () => void;
  children?: React.ReactNode;
};

export function Navigation({ activeId, collapsed = false, onToggle, children }: NavigationProps) {
  return (
    <nav className={cx(styles.nav, { [styles.collapsed]: collapsed })}>
      <button className={styles.toggleNavButton} onClick={onToggle}>
        <Icon name={collapsed ? 'chevrons-right' : 'chevrons-left'} className={styles.icon} />
      </button>
      <ul className={styles.menu}>
        {reportNavigation.map((item, i) => {
          return (
            <NavigationLink active={item.id === activeId} key={item.id} to={item.id} item={{ ...item, index: i }}>
              {children}
            </NavigationLink>
          );
        })}
      </ul>
    </nav>
  );
}
