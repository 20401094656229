import { When } from '@simplifiers/ui/When';
import { formatCurrency, roundNumber } from '../../../utils/formatters';
import { TableCellProps } from './types';

export function NumberCellContent(props: Omit<TableCellProps, 'level'>) {
  const { row, column } = props;

  const columnValue = formatCurrency(roundNumber(column.value)) || 0;

  if (row.type === 'row') {
    return <span>{columnValue}</span>;
  }

  const isAccountLabel = row.type === 'account';

  return (
    <When isTrue={isAccountLabel} fallback={<b>{columnValue}</b>}>
      {columnValue}
    </When>
  );
}
