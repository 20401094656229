import { Representative, RepresentativeRole } from '../types';

export const getExecutives = (representatives: Representative[]): Representative[] => {
  // TODO: add all executive role keys in this array
  const executiveRoles = ['VD'];
  const isExecutive = (roles: RepresentativeRole[]) => roles.find((role) => executiveRoles.includes(role.code)) != null;

  return representatives.filter((r) => isExecutive(r.roles));
};

export const getFounder = (representatives: Representative[]): Representative | undefined => {
  const isFounder = (roles: RepresentativeRole[]) => roles.find((role) => role.code === 'VD') != null;

  return representatives.find((r) => isFounder(r.roles));
};
