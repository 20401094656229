import { Bar as FBar } from '@fnox/eternal-smooth-ui';

export type ProgressBarProps = {
  fill: number;
  fillColor?: string;
  className?: string;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const { fill, fillColor, className } = props;

  return <FBar fill={fill} className={className} fillColor={fillColor} />;
};
