import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExternalLink, NavLink, SecondaryButton, ToggleButton } from '@simplifiers/ui/Button';
import { Text, Title } from '@simplifiers/ui/Typography';
import { When } from '@simplifiers/ui/When';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { answerQuestion, getQuestionById, getQuestionByNumber } from '../data';
import { OrderedQuestion } from '../types';
import styles from './Question.module.css';
import { Voucher } from './components/Voucher';

const questionQuery = (questionId: string) => ({
  queryKey: ['question', questionId],
  queryFn: async () => getQuestionById(questionId) as OrderedQuestion,
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: { params: { questionId: string } }) => {
    const query = questionQuery(params.questionId);
    return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
  };

export default function Question() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { questionId } = useParams();
  const { data, isLoading } = useQuery(questionQuery(questionId!));

  const createQuestionUrl = (categoryId: string, questionId: string) => {
    return `../${categoryId}/questions/${questionId}`;
  };

  const previousQuestionLink = useCallback(() => {
    if (data == null) {
      return '';
    }

    const previousQuestionNumber = data.questionNumber - 1;
    if (previousQuestionNumber < 1) {
      return '';
    }

    const previousQuestion = getQuestionByNumber(previousQuestionNumber);
    if (previousQuestion == null) {
      return '';
    }

    return createQuestionUrl(previousQuestion.categoryId, previousQuestion.question.id);
  }, [data]);

  const nextQuestionLink = useCallback(() => {
    if (data == null) {
      return '';
    }

    const nextQuestionNumber = data.questionNumber + 1;
    const nextQuestion = getQuestionByNumber(nextQuestionNumber);
    if (nextQuestion == null) {
      return '';
    }

    return createQuestionUrl(nextQuestion.categoryId, nextQuestion.question.id);
  }, [data]);

  const shouldShowAccountInformation = () => {
    if (data?.question.accounts == null) {
      return false;
    }

    const accounts = data.question.accounts;
    if (accounts.triggerAnswer == null) {
      return false;
    }

    const trigger = accounts.triggerAnswer?.toLocaleLowerCase() === 'true' ? 'yes' : 'no';
    if (data.answer != null && data.answer?.toLocaleLowerCase() === trigger) {
      return true;
    }

    return false;
  };

  const mutation = useMutation({
    mutationFn: answerQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accounts'] });
      queryClient.invalidateQueries({ queryKey: ['question'] });
    },
  });

  const handleSnoozeQuestion = () => {
    const nextQuestionUrl = nextQuestionLink();
    if (nextQuestionUrl) {
      navigate(nextQuestionUrl);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      let link = '';
      if (event.key === 'ArrowLeft') {
        const fullLink = previousQuestionLink();
        link = fullLink.replace(window.location.origin, '');
      }

      if (event.key === 'ArrowRight') {
        const fullLink = nextQuestionLink();
        link = fullLink.replace(window.location.origin, '');
      }

      if (link === '') {
        return;
      }

      navigate(link);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, nextQuestionLink, previousQuestionLink]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data == null) {
    return <div>Question with {questionId} not found</div>;
  }

  return (
    <>
      <Title large className={styles.title}>
        {data.categoryName}
      </Title>
      <div className={styles.questionContainer}>
        <div className={styles.question}>
          <Title>{data.question.title}</Title>
          <Text className={styles.description}>{data.question.description}</Text>

          {data.question.externalLinks.length > 0 &&
            data.question.externalLinks.map((l) => (
              <ExternalLink key={l.url} to={l.url}>
                {l.label}
              </ExternalLink>
            ))}

          <div className={styles.actions}>
            <ToggleButton
              iconName="check"
              active={data.answer === 'yes'}
              className={styles.button}
              onClick={() =>
                mutation.mutate({
                  questionId: data.question.id,
                  answer: 'yes',
                })
              }
            >
              Ja
            </ToggleButton>
            <ToggleButton
              iconName="check"
              active={data.answer === 'no'}
              className={styles.button}
              onClick={() =>
                mutation.mutate({
                  questionId: data.question.id,
                  answer: 'no',
                })
              }
            >
              Nej
            </ToggleButton>
            <SecondaryButton onClick={handleSnoozeQuestion} className={styles.button}>
              Svara senare
            </SecondaryButton>
          </div>

          {data.question.accounts != null && shouldShowAccountInformation() ? (
            <Voucher accountsInfo={data.question.accounts} onCreateVoucher={() => {}} />
          ) : null}
        </div>
      </div>

      <nav className={styles.nav}>
        <When isTrue={previousQuestionLink() !== ''} fallback={<span></span>}>
          <NavLink to={previousQuestionLink()} iconName="arrow-left">
            Föregående fråga
          </NavLink>
        </When>
        <When isTrue={nextQuestionLink() !== ''} fallback={<span></span>}>
          <NavLink to={nextQuestionLink()} iconName="arrow-right" iconPosition="end">
            Nästa fråga
          </NavLink>
        </When>
      </nav>
    </>
  );
}
