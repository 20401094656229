import { useState } from 'react';
import { Category } from '../../types';
import styles from './SubMenu.module.css';
import { SubMenuLink } from './SubMenuLink';

type SubMenuProps = {
  categories: Array<Category>;
};

export const SubMenu = ({ categories }: SubMenuProps) => {
  const initialExpandedCategory = categories[0].level === 1 ? categories[0].id : '';

  const [expandedCategory, setExpandedCategory] = useState<string>(initialExpandedCategory);

  const hasQuestions = (category: Category) => category.questions != null && category.questions.length > 0;

  const isCategoryCollapsed = (id: string) => expandedCategory !== id;

  const toggleCategoryExpansion = (id: string) => {
    const expandedId = isCategoryCollapsed(id) ? id : '';
    setExpandedCategory(expandedId);
  };

  return (
    <ul className={styles.subMenu}>
      {categories.map((category) => {
        return (
          <li key={category.id}>
            <SubMenuLink
              hasQuestions={hasQuestions(category)}
              category={category}
              isCollapsed={isCategoryCollapsed(category.id)}
              onToggleExpansion={() => toggleCategoryExpansion(category.id)}
            />
            {category.subCategories && !isCategoryCollapsed(category.id) && (
              <SubMenu categories={category.subCategories} />
            )}
          </li>
        );
      })}
    </ul>
  );
};
