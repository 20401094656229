import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Alert } from '@simplifiers/ui/Alert';
import { PrimaryButton, SecondaryButton } from '@simplifiers/ui/Button';
import { FormDatepicker, FormDropdown, FormFilterSelect, FormInput } from '@simplifiers/ui/Form';
import { Grid, GridItem } from '@simplifiers/ui/Layout/Grid';
import { Caption } from '@simplifiers/ui/Typography/Caption';
import cx from 'classnames';
import { CurrencyInput } from './CurrencyInput';
import styles from './VoucherForm.module.css';
import { getDifference, getSum } from './calculations';
import { emptyTransaction, initialFormValues, voucherSeriesOptions } from './constants';
import { validate } from './validation';

const getOptions = (accounts: Array<number>) => {
  return accounts ? accounts.map((a) => ({ label: a, value: a })) : [];
};

const getOptionLabel = (o) => `${o.value} - ${o.label}`;

const getAccountTitle = (data, accountNumber?: string): string => {
  if (!accountNumber) return '';
  const account = data.find((account) => account.value === accountNumber);
  return account ? account.label : '';
};

type VoucherFormProps = {
  creditAccounts: Array<number>;
  debitAccounts: Array<number>;
};

export function VoucherForm({ creditAccounts, debitAccounts }: VoucherFormProps) {
  const creditOptions = getOptions(creditAccounts);
  const debitOptions = getOptions(debitAccounts);

  const {
    control,
    formState: { isDirty, errors },
    watch,
    getValues,
    reset,
    setError,
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: initialFormValues,
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'transactions',
  });

  const transactions = watch('transactions');

  const debitSum = getSum(transactions, 'debit');
  const creditSum = getSum(transactions, 'credit');
  const difference = getDifference(debitSum, creditSum);

  const onSubmit = (data) => {
    const errors = validate(data, difference);

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key, value);
      });
      /* need to reset the form isDirty state in order to hide validation messages when user edits form values again */
      reset(getValues(), { keepDirty: false, keepDirtyValues: false, keepErrors: true });
      return;
    }
  };

  const appendFields = () => {
    append(emptyTransaction, { shouldFocus: false });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <legend>
        <Caption>Kontoregistrering</Caption>
      </legend>

      <Grid as="div" colGap="md" columns={{ base: 5 }} justifyItems="stretch" rowGap="md" rows={{ base: 1 }}>
        <GridItem colSpan={{ base: 3 }}>
          <FormInput name="description" label="Beskrivning" control={control} rules={{ required: true }} />
        </GridItem>
        <GridItem colSpan={{ base: 1 }}>
          <FormFilterSelect name="series" label="Verifikationsserie" options={voucherSeriesOptions} control={control} />
        </GridItem>
        <GridItem colSpan={{ base: 1 }}>
          <FormDatepicker name="date" label="Date" control={control} />
        </GridItem>
      </Grid>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.accountNumber}>Konto</th>
              <th>Kontobenämning</th>
              <th className={cx(styles.amount, styles.alignRight)}>Debet</th>
              <th className={cx(styles.amount, styles.alignRight)}>Kredit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <tr>
                  <td>
                    <FormDropdown
                      title="Förslag på relevanta konton"
                      control={control}
                      name={`transactions.${index}.debitAccount`}
                      options={debitOptions}
                      optionLabelFormatter={getOptionLabel}
                    />
                  </td>
                  <td>
                    <span>{getAccountTitle(debitOptions, transactions[index].debitAccount)}</span>
                  </td>
                  <td>
                    <CurrencyInput control={control} name={`transactions.${index}.debit`} />
                  </td>
                  <td className={styles.disabled}></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <FormDropdown
                      title="Förslag på relevanta konton"
                      control={control}
                      name={`transactions.${index}.creditAccount`}
                      options={creditOptions}
                      optionLabelFormatter={getOptionLabel}
                    />
                  </td>
                  <td>
                    <span>{getAccountTitle(creditOptions, transactions[index].creditAccount)}</span>
                  </td>
                  <td className={styles.disabled}></td>
                  <td>
                    <CurrencyInput control={control} name={`transactions.${index}.credit`} />
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>
            ))}

            <tr>
              <th colSpan={2} className={styles.alignRight}>
                Summa
              </th>
              <td className={styles.alignRight}>
                <span>{debitSum}</span>
              </td>
              <td className={styles.alignRight}>
                <span>{creditSum}</span>
              </td>
              <td></td>
            </tr>

            <tr>
              <th colSpan={2} className={styles.alignRight}>
                Differens
              </th>
              <td />
              <td className={styles.alignRight}>
                <span>{difference}</span>
              </td>
              <td></td>
            </tr>

            <tr>
              <td colSpan={5}>
                <button type="button" onClick={appendFields}>
                  Lägg till fler konton
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {!isDirty &&
        errors.root &&
        Object.entries(errors.root).map(([key, err]) => (
          <div key={key} className={styles.alert}>
            <Alert maxWidth={532}>{err.message}</Alert>
          </div>
        ))}

      <div className={styles.actions}>
        <SecondaryButton type="button">Fyll i senare</SecondaryButton>
        <PrimaryButton disabled={debitSum === 0 || creditSum === 0} type="submit">
          Bokför preliminärt
        </PrimaryButton>
      </div>
    </form>
  );
}
