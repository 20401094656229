import { Tooltip } from '../../Tooltip/Tooltip';
import { Text } from '../Text';
import styles from './PersonalNumber.module.css';

type PersonalNumberProps = {
  value: string;
};

/**
 * Personal number with hidden digits at the end. In compliance with GDPR regulations.
 * Accessible with Tab + Enter when focused.
 *
 * @param value social security number with format YYYYMMDD-XXXX
 * @returns Personal number (YYYYMMDD) with hidden digits XXXX revealed on hover
 */
export const PersonalNumber = ({ value }: PersonalNumberProps) => {
  const hiddenDigits = <span className={styles.hiddenDigits}>XXXX</span>;

  if (value.length === 0) {
    return null;
  }

  return (
    <Text className={styles.personalNumber}>
      {value.slice(0, -4)}
      <Tooltip value={hiddenDigits} text={value.slice(-4)} position="right" className={styles.tooltip} />
    </Text>
  );
};
