import { Caption, Text } from '@simplifiers/ui/Typography';
import styles from './InformationItem.module.css';

type InformationItemProps = {
  title: string;
  value: string;
};

export const InformationItem = ({ title, value }: InformationItemProps) => {
  return (
    <div className={styles.informationItem}>
      <Caption>{title}</Caption>
      <Text>{value}</Text>
    </div>
  );
};
