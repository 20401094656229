import { RouteObject } from 'react-router-dom';
import { Report } from './Report';
import { accountsRoute } from './pages/Accounts/route';
import { annualReportRoutes } from './pages/AnnualReport/route';
import { questionsRoutes } from './pages/Questions/route';
import { signingRoutes } from './pages/Signing/route';
import { submissionRoutes } from './pages/Submission/route';
import { taxRoutes } from './pages/Tax/route';

export const reportRoutes: RouteObject = {
  path: 'report/:financialYearId',
  element: <Report />,
  children: [questionsRoutes, annualReportRoutes, signingRoutes, submissionRoutes, taxRoutes, accountsRoute],
};
