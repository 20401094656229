import styles from './SubMenu.module.css';
import { SubMenuListItem } from './SubMenuListItem';
import { SubMenuItem } from './types';

type SubMenuProps = {
  parts?: Array<SubMenuItem>;
  parentId: string;
};

export const SubMenu = ({ parts, parentId }: SubMenuProps) => {
  if (!parts?.length) {
    return null;
  }

  return (
    <ul className={styles.subMenu}>
      {parts.map((item) => {
        const fullId = `${parentId}/${item.id}`;
        return <SubMenuListItem key={fullId} item={item} fullId={fullId} />;
      })}
    </ul>
  );
};
