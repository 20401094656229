import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Dropdown, DropdownProps } from './Dropdown';

export type FormDropdownProps<T> = UseControllerProps<any> &
  Pick<DropdownProps<T>, 'title' | 'options' | 'className' | 'disabled' | 'optionLabelFormatter'>;

export const FormDropdown = React.forwardRef(
  (
    { title, options, className, disabled, optionLabelFormatter, ...props }: FormDropdownProps<any>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { field } = useController(props);

    return (
      <Dropdown
        title={title}
        options={options}
        className={className}
        disabled={disabled}
        optionLabelFormatter={optionLabelFormatter}
        {...field}
        ref={ref}
      />
    );
  }
);
