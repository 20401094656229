import React from 'react';
import styles from './Content.module.css';

type ContentProps = {
  children: React.ReactNode;
};

export function Content({ children }: ContentProps) {
  return (
    <main className={styles.content}>
      <div className={styles.contentContainer}>{children}</div>
    </main>
  );
}
