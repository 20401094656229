import { useState } from 'react';
import { ButtonItem, DropdownButton as FDropdownButton } from '@fnox/eternal-smooth-ui';

type DropdownButtonProps = {
  name: string;
  disabled?: boolean;
  items: string[];
  onSelect: (item: string) => void;
};

export const DropdownButton = (props: DropdownButtonProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const { name, disabled, items, onSelect } = props;

  const toggleDropdown = () => {
    setIsToggled(!isToggled);
  };

  return (
    <FDropdownButton content={name} disabled={disabled} isToggled={isToggled} toggleDropdown={toggleDropdown}>
      {items.map((item, index) => {
        return (
          <ButtonItem key={`${item}-${index}`} onSelect={() => onSelect(item)}>
            {item}
          </ButtonItem>
        );
      })}
    </FDropdownButton>
  );
};
