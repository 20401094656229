import { Grid as FGrid } from '@fnox/eternal-smooth-ui';

// we use all the FGrid props
type GridProps = typeof FGrid extends (props: infer T) => any ? T : never;

export const Grid = (props: GridProps) => {
  const {
    children,
    as,
    className,
    columns,
    colGap,
    rows,
    rowGap,
    justifyItems,
    justifyContent,
    alignContent,
    alignItems,
    testID,
  } = props;

  return (
    <FGrid
      as={as}
      className={className}
      columns={columns}
      colGap={colGap}
      rows={rows}
      rowGap={rowGap}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignContent={alignContent}
      alignItems={alignItems}
      testID={testID}
    >
      {children}
    </FGrid>
  );
};
