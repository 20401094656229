import { useOutletContext } from 'react-router-dom';
import { DropdownButton } from '@simplifiers/ui/Button/DropdownButton/DropdownButton';
import { PrimaryButton } from '@simplifiers/ui/Button/Primary/PrimaryButton';
import { Container } from '@simplifiers/ui/Layout/Container';
import { Grid } from '@simplifiers/ui/Layout/Grid';
import { Title } from '@simplifiers/ui/Typography';
import { ReportStep } from '../../components/ReportStep';
import { FinancialYearReportContext, ReportStepStatus } from '../../types';
import styles from './CurrentYear.module.css';

const documentOptions = [
  { value: 'annual-report', label: 'Årsredovisning' },
  { value: 'accounting-documents', label: 'Bokslutsdokument' },
];

const documentLabels = documentOptions.map(({ label }) => label);

export const CurrentYear = () => {
  const { steps, setSteps, isReportDone, initialSteps } = useOutletContext<FinancialYearReportContext>();

  const partTitle = isReportDone ? 'Bokslut 2024' : 'Hur långt du har kommit';

  const handleToggleAllStatuses = () => {
    if (isReportDone) {
      setSteps([...initialSteps]);
    } else {
      setSteps(steps.map((step) => ({ ...step, status: ReportStepStatus.DONE })));
    }
  };

  return (
    <Container paddingVertical="xl">
      <div className={styles.headerContainer}>
        <Title>{partTitle}</Title>
        <div className={styles.buttonContainer}>
          <PrimaryButton onClick={handleToggleAllStatuses}>
            {isReportDone ? 'Återställ status' : 'Markera alla som klara'}
          </PrimaryButton>
          {isReportDone && <DropdownButton name="Ladda ner" items={documentLabels} onSelect={() => {}} />}
        </div>
      </div>

      <Grid
        as="div"
        colGap="xl"
        columns={{
          base: 5,
        }}
        justifyItems="stretch"
        rowGap="md"
      >
        {steps.map((step) => (
          <ReportStep key={step.step} step={step.step} title={step.title} status={step.status} />
        ))}
      </Grid>
    </Container>
  );
};
