import { NavLink, Outlet } from 'react-router-dom';
import cx from 'classnames';
import styles from './Start.module.css';

const startTabs = [
  { title: 'Enkelt Årsavslut', path: 'overview' },
  { title: 'Grunduppgifter', path: 'information' },
  { title: 'Behandlingshistorik', path: 'activity-log' },
];

export function Start() {
  return (
    <main className={styles.container}>
      <nav className={styles.tabs}>
        {startTabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            className={({ isActive }) => cx(styles.tab, { [styles.active]: isActive })}
          >
            {tab.title}
          </NavLink>
        ))}
      </nav>
      <div className={styles.content}>
        <Outlet />
      </div>
    </main>
  );
}
