import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { formatCurrency } from './formatters';

export const CurrencyInput = React.forwardRef(
  (
    { name, control, ...props }: UseControllerProps<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { field } = useController({ name, control });

    return (
      <input
        type="number"
        inputMode="decimal"
        min={0}
        step={0.01}
        {...props}
        {...field}
        onChange={(e) => field.onChange(formatCurrency(e.target.value))}
        ref={ref}
      />
    );
  }
);
