import { GridItem as FGridItem } from '@fnox/eternal-smooth-ui';

// we use all the FGridItem props
type GridItemProps = typeof FGridItem extends (props: infer T) => any ? T : never;

export const GridItem = (props: GridItemProps) => {
  const {
    children,
    as,
    className,
    justifyItems,
    justifyContent,
    alignContent,
    alignItems,
    colStart,
    colEnd,
    rowStart,
    rowEnd,
    colSpan,
    rowSpan,
    display,
    flexDirection,
  } = props;

  return (
    <FGridItem
      as={as}
      className={className}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignContent={alignContent}
      alignItems={alignItems}
      colStart={colStart}
      colEnd={colEnd}
      rowStart={rowStart}
      rowEnd={rowEnd}
      colSpan={colSpan}
      rowSpan={rowSpan}
      display={display}
      flexDirection={flexDirection}
    >
      {children}
    </FGridItem>
  );
};
