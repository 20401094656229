import { useEffect, useState } from 'react';
import { NavLink, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { Icon } from '@simplifiers/ui/Icon';
import cx from 'classnames';
import { SubMenu } from './SubMenu';
import styles from './SubMenu.module.css';
import { SubMenuItem } from './types';

type SubMenuListItemProps = {
  item: SubMenuItem;
  fullId: string;
};

export const SubMenuListItem = ({ item, fullId }: SubMenuListItemProps) => {
  const location = useLocation();
  const resolved = useResolvedPath(fullId);
  const match = useMatch({ path: `${resolved.pathname}/*`, end: true });
  const [expanded, setExpanded] = useState<boolean>(!!match);

  const hasChildren = item.parts && item.parts.length > 0;

  useEffect(() => {
    setExpanded(!!match);
    /** Do not add match to dependency array. Match is calculated based on location.
     * And for active menu item "match" is always true,
     * so it won't be possible to collapse active menu item.
     * While we want to automatically expand only when location changes.
     * It should be safe since match depends on location. */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname]);

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <li>
      <NavLink to={fullId} className={cx(styles.link, { [styles.active]: match })}>
        {hasChildren && (
          <button onClick={handleToggle} className={styles.toggleButton}>
            {expanded ? (
              <Icon name="chevron-up" className={styles.icon} size="2xs" />
            ) : (
              <Icon name="chevron-down" className={styles.icon} size="2xs" />
            )}
          </button>
        )}
        <Icon name="circle" size="2xs" className={styles.statusIcon} />
        {/*<Icon name="circle-check" size="2xs" color="var(--action-color)" />*/}
        <span className={styles.name}>{item.name}</span>
      </NavLink>

      {hasChildren && expanded && <SubMenu parts={item.parts} parentId={fullId} />}
    </li>
  );
};
