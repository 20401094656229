import { Navigate, RouteObject } from 'react-router-dom';
import { Start } from './Start';
import { activityLogRoutes } from './pages/ActivityLog/route';
import { basicInformationRoutes } from './pages/BasicInformation/route';
import { overviewRoutes } from './pages/Overview/route';
import { sieImportRoutes } from './pages/SieImport/route';

export const startRoutes: RouteObject = {
  path: '',
  element: <Start />,
  children: [
    { index: true, element: <Navigate to="overview" replace /> },
    overviewRoutes,
    basicInformationRoutes,
    activityLogRoutes,
    sieImportRoutes,
  ],
};
