import { TableRow } from './types';

/**
 * Filters and processes table rows, removing rows without values and empty sections.
 *
 * This function recursively processes an array of TableRow objects:
 * - Header rows are kept only if they have non-empty child rows.
 * - Sum and alwaysDisplaySum rows are kept if they have a year value or non-empty child rows.
 * - Regular rows are kept only if they have a year value.
 *
 * @param rows - The array of table rows to be filtered and processed.
 * @returns {TableRow[]} A new array of TableRow objects with empty rows and sections removed.
 *
 * @example
 * const rows = [
 *   {
 *     type: 'header',
 *     id: 'section1',
 *     rows: [
 *       { type: 'row', id: 'row1', cells: { year: { value: 100 } } },
 *       { type: 'row', id: 'row2', cells: { year: { value: null } } }
 *     ]
 *   },
 *   {
 *     type: 'sum',
 *     id: 'total',
 *     cells: { year: { value: 100 } }
 *   }
 * ];
 * const filteredRows = filterRowsWithValues(rows);
 * // Result:
 * // [
 * //   {
 * //     type: 'header',
 * //     id: 'section1',
 * //     rows: [
 * //       { type: 'row', id: 'row1', cells: { year: { value: 100 } } }
 * //     ]
 * //   },
 * //   {
 * //     type: 'sum',
 * //     id: 'total',
 * //     cells: { year: { value: 100 } }
 * //   }
 * // ]
 */
export const filterRowsWithValues = (rows: TableRow[]): TableRow[] => {
  return rows.reduce((acc, row) => {
    if (row.type === 'header') {
      const processedRows = filterRowsWithValues(row.rows ?? []);
      if (processedRows.length > 0) {
        acc.push({
          ...row,
          rows: processedRows,
        });
      }
    } else if (row.type === 'sum' || row.type === 'alwaysDisplaySum') {
      const hasValue = row.cells?.year?.value;
      const processedRows = filterRowsWithValues(row.rows ?? []);
      if (hasValue || processedRows.length > 0) {
        acc.push({
          ...row,
          rows: processedRows,
        });
      }
    } else if (row.type === 'row' && row.cells?.year?.value) {
      acc.push(row);
    }
    return acc;
  }, [] as TableRow[]);
};
