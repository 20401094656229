import { DefaultService as AutogeneratedService, OpenAPI } from '@agoy/api-sdk-core';
import { token } from './auth';

export type ApiSdk = typeof AutogeneratedService;

const ApiService = (config: typeof OpenAPI, service: ApiSdk): ApiSdk => {
  config.HEADERS = { Token: token };
  return service;
};

const getApiService = () => {
  return ApiService(OpenAPI, AutogeneratedService);
};

export const apiService = getApiService();
