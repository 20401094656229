import { NavLink, useParams } from 'react-router-dom';
import { Icon } from '@simplifiers/ui/Icon';
import { When } from '@simplifiers/ui/When';
import cx from 'classnames';
import { areQuestionsOfCategoryAnswered } from '../../data';
import { Category } from '../../types';
import styles from './SubMenu.module.css';

type SubMenuLinkProps = {
  category: Category;
  isCollapsed: boolean;
  hasQuestions: boolean;
  onToggleExpansion: () => void;
};

export const SubMenuLink = ({ category, isCollapsed, hasQuestions, onToggleExpansion }: SubMenuLinkProps) => {
  const { categoryId } = useParams();
  const hasSubCategories = category.subCategories && category.subCategories.length > 0;

  if (!hasQuestions) {
    return (
      <button className={styles.link} onClick={onToggleExpansion}>
        <When isTrue={!!hasSubCategories}>
          <span className={styles.toggleButton}>
            <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} className={styles.statusIcon} size="2xs" />
          </span>
        </When>
        <Icon name="circle" className={styles.statusIcon} size="2xs" />
        <span className={styles.name}>{category.name}</span>
      </button>
    );
  }

  const link = `${category.id}/questions/${category.questions[0]?.id}`;
  const areAllQuestionsAnswered = areQuestionsOfCategoryAnswered(category.id!);

  return (
    <NavLink to={link} className={cx(styles.link, { [styles.active]: categoryId === category.id })}>
      <When isTrue={!!hasSubCategories}>
        <button onClick={onToggleExpansion} className={styles.toggleButton}>
          <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} className={styles.icon} size="2xs" />
        </button>
      </When>
      <Icon
        name={areAllQuestionsAnswered ? 'circle-check' : 'circle'}
        className={styles.statusIcon}
        color={areAllQuestionsAnswered && 'var(--action-color)'}
        size="2xs"
      />
      <span className={styles.name}>{category.name}</span>
    </NavLink>
  );
};
