import { TableHeader as FTableHeader } from '@fnox/eternal-smooth-ui';

type TableHeaderProps = {
  children: React.ReactNode | React.ReactNode[];
  align?: 'center' | 'left' | 'right' | undefined;
  center?: boolean | undefined;
  width?: string | number | undefined;
  minWidth?: string | number | undefined;
  textSize?: 'small' | undefined;
  colSpan?: number | undefined;
  sort?: 'desc' | 'asc' | undefined;
  sortable?: boolean | undefined;
  rowHeight?: '48' | '56' | '80' | undefined;
  onSort?: ((order: 'asc' | 'desc') => void) | undefined;
  onClick?: ((event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void) | undefined;
  testID?: string | undefined;
};

export function TableHeader(props: TableHeaderProps) {
  const {
    children,
    align,
    center,
    width,
    minWidth,
    textSize,
    colSpan,
    sort,
    sortable,
    rowHeight,
    onSort,
    onClick,
    testID,
  } = props;
  return (
    <FTableHeader
      align={align}
      center={center}
      width={width}
      minWidth={minWidth}
      textSize={textSize}
      colSpan={colSpan}
      sort={sort}
      sortable={sortable}
      rowHeight={rowHeight}
      onSort={onSort}
      onClick={onClick}
      testID={testID}
    >
      {children}
    </FTableHeader>
  );
}
