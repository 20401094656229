import React from 'react';
import cx from 'classnames';
import styles from './Paper.module.css';

export type PaperProps = {
  children: React.ReactNode;
  className?: string;
  type: 'success' | 'warning' | 'info' | 'error' | 'default';
};

export function Paper(props: PaperProps) {
  const { children, className, type = 'default' } = props;

  return (
    <div
      className={cx(styles.paper, className, {
        [styles.success]: type === 'success',
        [styles.warning]: type === 'warning',
        [styles.error]: type === 'error',
        [styles.info]: type === 'info',
      })}
    >
      {children}
    </div>
  );
}
