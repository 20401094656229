import { Heading } from '@fnox/eternal-smooth-ui';

type SubTitleProps = {
  children: React.ReactElement | string;
  className?: string;
};

export const SubTitle = (props: SubTitleProps) => {
  const { children, className } = props;

  return (
    <>
      <Heading as={'h4'} className={className}>
        {children}
      </Heading>
    </>
  );
};
