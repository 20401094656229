import { Link } from '@fnox/eternal-smooth-ui';

type ExternalLinkProps = {
  children: React.ReactElement | string;
  to?: string;
  className?: string;
};

export const ExternalLink = ({ children, to, className }: ExternalLinkProps) => {
  return (
    <Link variant="external" to={to} className={className}>
      {children}
    </Link>
  );
};
