import { Representative } from '../types';

export const getRepresentativeFullName = (representative: Representative): string => {
  const { firstName, lastName } = representative;
  if (firstName.length === 0) {
    return lastName;
  }

  return `${firstName} ${lastName}`;
};
