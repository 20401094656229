import mergeAll from 'lodash/fp/mergeAll';
import 'moment/locale/sv';
import moment from 'moment';
import { addLocale, useLocale } from 'ttag';
import en from '../../../i18n/en.po.json';

const defaultLocale = import.meta.env.VITE_LOCALE || 'sv'; // Default source language is Swedish

export const initLocales = async (locale = defaultLocale): Promise<'en' | null> => {
  moment.locale(locale);
  /**
   * We want to add and use a locale only if it's different from the default swedish.
   */
  if (locale === 'en') {
    const locales = mergeAll([en]);
    addLocale(locale, locales);
    useLocale(locale); // eslint-disable-line
    return locale;
  }

  return null;
};
