import { useMemo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@simplifiers/ui/Table';
import classNames from 'classnames';
import { AnnualReportColumn, AnnualReportRow } from '../../pages/Document/types';
import styles from './AnnualReportTable.module.css';
import { TableCellContents } from './TableCellContents';
import { AnnualReportTableProps } from './types';

export function AnnualReportTable(props: AnnualReportTableProps) {
  const { data, areAccountsShown } = props;

  const filteredRows = useMemo((): AnnualReportRow[] => {
    const newFilteredRows = areAccountsShown ? data.rows : data.rows.filter((row) => row.type !== 'account');

    return newFilteredRows;
  }, [data.rows, areAccountsShown]);

  const getRowClass = (row: AnnualReportRow, index: number): string => {
    const baseClasses = classNames(styles[row.type], styles[`level${row.level}`]);

    if (row.type === 'sum' && row.level === 1) {
      return classNames(baseClasses, styles.sumLevel1);
    }

    if (row.type === 'header' && row.level === 2) {
      const previousRow = filteredRows[index - 1];
      if (previousRow?.type === 'section') {
        return classNames(baseClasses, styles.subHeader, styles.noBorderTop);
      }

      return classNames(baseClasses, styles.subHeader);
    }

    return baseClasses;
  };

  const getCellClass = (cell: AnnualReportColumn) => {
    return classNames(styles[`${cell.dataType}Cell`]);
  };

  return (
    <Table tableContainerClassName={styles.annualReportTable} tableHeaderClassName={styles.thNoPaddingLeft}>
      <TableHead>
        {data.columns.map((col) => (
          <TableHeader key={col.id} align={col.dataType === 'number' ? 'right' : 'left'}>
            {col.value}
          </TableHeader>
        ))}
      </TableHead>

      <TableBody>
        {filteredRows.map((row, index: number) => (
          <TableRow key={row.id} className={getRowClass(row, index)}>
            {row.cells.map((cell) => (
              <TableCell key={`${row.id}-${cell.id}`} className={getCellClass(cell)}>
                <TableCellContents row={row} column={cell} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
