import { client } from 'api/config';
import { QueryClient } from '@tanstack/react-query';
import { getCompanyInformation } from './api';

export const basicCompanyInformationQuery = (companyId: string) => ({
  queryKey: ['companyInformation', companyId],
  queryFn: async () => getCompanyInformation(companyId),
});

export const basicInformationLoader = (queryClient: QueryClient) => async () => {
  //TODO: we should pass in the id in the params
  // async ({ params }) => {
  const query = basicCompanyInformationQuery(client.id);
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
};
