import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import cx from 'classnames';
import styles from './Report.module.css';

export function Report() {
  const [collapsed, setCollapsed] = useState(false);
  const toggleNavigation = () => setCollapsed(!collapsed);

  return (
    <div className={cx(styles.container, { [styles.navCollapsed]: collapsed })}>
      <Outlet context={{ collapsed, toggleNavigation }} />
    </div>
  );
}
