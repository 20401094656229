import { useEffect, useState } from 'react';
import { initLocales } from './locales';

/**
 * Runtime loading translations using `ttag`.
 * @example usage
	<Localize>
		{(locale) => (<YourAppGoesHere />)}
	</Localize>
 */
export const Localize = ({ children }: { children: (locale: string) => JSX.Element }) => {
  const [, updateState] = useState<object>();
  const defaultLocale = import.meta.env.VITE_LOCALE || 'sv';

  // TODO get locale from user
  const locale = defaultLocale;

  useEffect(() => {
    const init = locale?.split('_')[0] || 'sv';
    initLocales(init).then((locale) => locale && updateState({}));
  }, [locale]);

  return children(locale);
};
