import { Icon } from '@simplifiers/ui/Icon/Icon';
import { Caption, SubTitle, Text } from '@simplifiers/ui/Typography';
import { Accounts } from '../../../types';
import { VoucherForm } from '../VoucherForm/VoucherForm';
import styles from './Voucher.module.css';
import { getAllAccounts } from './accounts';

export type VoucherProps = {
  accountsInfo: Accounts;
  onCreateVoucher: () => void;
};

export const Voucher = ({ accountsInfo }: VoucherProps) => {
  const { title, helperDescriptions = [] } = accountsInfo;

  return (
    <div className={styles.voucherContent}>
      <div className={styles.tip}>
        <header className={styles.header}>
          <Icon className={styles.icon} name="lightbulb-on" />
          <Caption>{title}</Caption>
        </header>

        {helperDescriptions.map((helper, index) => (
          <div className={styles.descriptionContainer} key={index}>
            {helper.description && <SubTitle>{helper.description}</SubTitle>}

            <div className={styles.description}>
              {helper.debitNote && (
                <Text className={styles.paragraph}>
                  <span dangerouslySetInnerHTML={{ __html: helper.debitNote }} />
                </Text>
              )}

              {helper.creditNote && (
                <Text className={styles.paragraph}>
                  <span dangerouslySetInnerHTML={{ __html: helper.creditNote }} />
                </Text>
              )}
            </div>
          </div>
        ))}
      </div>

      <VoucherForm
        creditAccounts={getAllAccounts(accountsInfo.creditRange)}
        debitAccounts={getAllAccounts(accountsInfo.debitRange)}
      />
    </div>
  );
};
