import { Navigate, RouteObject } from 'react-router-dom';
import { PaymentAndVouchers } from './PaymentAndVouchers';
import { previewPaymentRoutes } from './pages/PreviewPayment/route';
import { vouchersRoutes } from './pages/Vouchers/route';

export const paymentAndVouchersRoutes: RouteObject = {
  path: 'payment-and-vouchers',
  element: <PaymentAndVouchers />,
  children: [
    { index: true, element: <Navigate to="preview-payment" replace /> },
    { path: '*', element: <Navigate to="preview-payment" replace /> },
    previewPaymentRoutes,
    vouchersRoutes,
  ],
};
