import React from 'react';
import { Filter, FilterOption } from '@fnox/eternal-smooth-ui';

export type SelectOption = {
  label: string;
  value: string;
};

export type FilterSelectProps = {
  name: string;
  label?: string;
  options: Array<SelectOption>;
  disabled?: boolean;
  size?: 'small' | 'default';
  value: string;
  onChange: (v: string) => void;
};
export const FilterSelect = React.forwardRef(
  (
    { label = '', options, value, onChange, ...props }: FilterSelectProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Filter title={label} {...props} value={value} onSelect={onChange} inputRef={ref}>
        {options.map((o) => (
          <FilterOption key={o.value} searchValue={o.label} value={o.value}>
            {o.label}
          </FilterOption>
        ))}
      </Filter>
    );
  }
);
