import { Outlet, useOutletContext } from 'react-router-dom';
import { When } from '@simplifiers/ui/When';
import { Content } from '../../components/Content';
import { Navigation } from '../../components/Navigation';
import { SubMenu } from '../../components/SubMenu';
import { SubMenuItem } from '../../components/SubMenu/types';
import { NavigationContext } from '../../types';

const submissionNav: Array<SubMenuItem> = [
  { id: 'digital-submission', name: 'Inlämning till Bolagsverket', status: 'incomplete' },
  { id: 'archive', name: 'Arkivering', status: 'incomplete' },
];

export function Submission() {
  const { collapsed, toggleNavigation } = useOutletContext<NavigationContext>();

  return (
    <>
      <Navigation activeId="submission" collapsed={collapsed} onToggle={toggleNavigation}>
        <When isTrue={!collapsed}>
          <SubMenu parentId="../submission" parts={submissionNav} />
        </When>
      </Navigation>
      <Content>
        <Outlet />
      </Content>
    </>
  );
}
