import { Icon, IconNameType, IconSize } from '@simplifiers/ui/Icon/Icon';
import cx from 'classnames';
import styles from './ToggleButton.module.css';

type ToggleButtonProps = {
  children: React.ReactElement | string[] | string;
  iconName?: IconNameType;
  iconSize?: IconSize;
  size?: 'small' | 'medium' | 'large';
  type?: 'outlined' | 'contained';
  disabled?: boolean;
  active?: boolean;
  className?: string;
  onClick?: () => void;
};

export const ToggleButton = (props: ToggleButtonProps) => {
  const {
    children,
    iconName,
    iconSize = 'xs',
    size = 'medium',
    type = 'contained',
    disabled,
    active,
    className,
    onClick,
  } = props;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cx(styles.button, { [styles.active]: active }, styles[size], styles[type], className)}
    >
      {active && iconName && <Icon name={iconName} size={iconSize as IconSize} className={styles.icon} />}
      {children}
    </button>
  );
};
