import { NumberCellContent } from './NumericCellContent';
import { StringCellContent } from './StringCellContent';
import { TableCellProps } from './types';

export function TableCellContents(props: TableCellProps) {
  const { row, column } = props;

  if (column.value === null || column.value === undefined || column.value === '') return null;

  if (column.dataType === 'string') {
    return <StringCellContent row={row} column={column} />;
  }

  return <NumberCellContent row={row} column={column} />;
}
