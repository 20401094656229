import { useEffect, useRef } from 'react';
import cx from 'classnames';
import styles from './Dropdown.module.css';

export interface DropdownOptionProps {
  label: string;
  value: string | number;
  focused?: boolean;
  selected?: boolean;
  onClick: (value: string | number) => void;
}

export const DropdownOption = ({ label, value, focused, selected, onClick }: DropdownOptionProps) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused]);

  return (
    <li
      onClick={() => onClick(value)}
      className={cx(styles.dropdownOption, { [styles.selected]: selected })}
      role="option"
      aria-selected={selected}
      tabIndex={-1}
      ref={ref}
    >
      {label}
    </li>
  );
};
