import { TableCell as FTableCell } from '@fnox/eternal-smooth-ui';

type TableCellProps = {
  children?: React.ReactNode | React.ReactNode[];
  align?: 'center' | 'left' | 'right' | undefined;
  colSpan?: number | undefined;
  onClick?: (() => void) | undefined;
  className?: string | undefined;
  sortValue?: string | number | undefined;
  testID?: string | undefined;
};

export function TableCell(props: TableCellProps) {
  const { children, align, colSpan, onClick, className, sortValue, testID } = props;
  return (
    <FTableCell
      align={align}
      colSpan={colSpan}
      className={className}
      sortValue={sortValue}
      testID={testID}
      onClick={onClick}
    >
      {children}
    </FTableCell>
  );
}
