import { Outlet, useOutletContext } from 'react-router-dom';
import { When } from '@simplifiers/ui/When';
import { Content } from '../../components/Content';
import { Navigation } from '../../components/Navigation';
import { SubMenu } from '../../components/SubMenu';
import { SubMenuItem } from '../../components/SubMenu/types';
import { NavigationContext } from '../../types';

const signingNav: Array<SubMenuItem> = [
  { id: 'signatories', name: 'Undertecknare', status: 'incomplete' },
  { id: 'annual-report-signing', name: 'Årsredovisning signering', status: 'incomplete' },
  { id: 'annual-meeting-signing', name: 'Årsstämmoprotokoll signering', status: 'incomplete' },
];
export function Signing() {
  const { collapsed, toggleNavigation } = useOutletContext<NavigationContext>();

  return (
    <>
      <Navigation activeId="signing" collapsed={collapsed} onToggle={toggleNavigation}>
        <When isTrue={!collapsed}>
          <SubMenu parentId="../signing" parts={signingNav} />
        </When>
      </Navigation>
      <Content>
        <Outlet />
      </Content>
    </>
  );
}
