import { ProgressBar as ProgressBarComponent } from '@simplifiers/ui/ProgressBar';
import cx from 'classnames';
import styles from './ProgressBar.module.css';

type ProgressBarProps = {
  fill: number;
  className?: string;
};

const getFillColor = (fill: number) => {
  let fillColor;
  if (fill > 0 && fill < 100) {
    fillColor = 'var(--bg-yellow-full)';
  } else if (fill === 100) {
    fillColor = 'var(--bg-dark-green-med)';
  }

  return fillColor;
};

export const ProgressBar = ({ fill, className }: ProgressBarProps) => {
  return <ProgressBarComponent fill={fill} fillColor={getFillColor(fill)} className={cx(styles.bar, className)} />;
};
