import { GridItem } from '@simplifiers/ui/Layout/Grid';
import { ProgressBar } from '@simplifiers/ui/ProgressBar';
import { Text } from '@simplifiers/ui/Typography';
import styles from './Progress.module.css';

type ProgressItemProps = {
  isActive?: boolean;
  title: string;
  answers: number;
  total: number;
}

export const ProgressItem = ({ isActive, title, answers, total }: ProgressItemProps) => {
  const fill = answers / total * 100;

  return (
    <GridItem className={styles.section}>
      <ProgressBar fill={fill} className={styles.progressBar} />
      {isActive && <Text className={styles.title}>{title} fråga {answers} av {total}</Text>}
      {!isActive && <Text color="#bfc6c4" className={styles.title}>{title} {answers}/{total}</Text>}
    </GridItem>
  );
};
