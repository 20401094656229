import { PrimaryButton as FPrimaryButton } from '@fnox/eternal-smooth-ui';
import { Icon, IconNameType, IconSize } from '@simplifiers/ui/Icon/Icon';

type PrimaryButtonProps = {
  testID?: string;
  type?: 'submit' | 'reset' | 'button';
  children: React.ReactElement | string[] | string;
  iconName?: IconNameType;
  iconPosition?: 'left' | 'right';
  iconSize?: IconSize;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const { testID, type, children, iconName, iconPosition, iconSize, size, disabled, className, onClick } = props;

  return (
    <FPrimaryButton
      testID={testID}
      type={type}
      onClick={onClick}
      icon={iconName ? <Icon name={iconName} size={iconSize} /> : undefined}
      iconPosition={iconPosition}
      disabled={disabled}
      size={size}
      className={className}
    >
      {children}
    </FPrimaryButton>
  );
};
